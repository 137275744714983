import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { doc, getFirestore,collection } from 'firebase/firestore';
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp,
  useSigninCheck,
  AuthProvider
} from 'reactfire';

import Photos from './components/Photos';
//import Ho from './components/HomeComp';
import GS from './components/GaussianSplatComp3';

const cors=require("cors");
const corsOptions ={
   origin:'*', 
   credentials:true,            //access-control-allow-credentials:true
   optionSuccessStatus:200,
}


// require('./js/initgiga.js');
//require('./js/giga_imports.js');
//require('./js/utils.js');
//require('./js/gigascope.js');

const root = ReactDOM.createRoot(document.getElementById('root'));

const firebaseConfig = {
  apiKey: "AIzaSyAQDd5T2sWBzV_CQsSmlil_YO4PBPmp968",
  authDomain: "newgigascopejs.firebaseapp.com",
  databaseURL: "https://newgigascopejs.firebaseio.com",
  projectId: "newgigascopejs",
  storageBucket: "newgigascopejs.appspot.com",
  messagingSenderId: "229995449109",
  appId: "1:229995449109:web:893b34b67545f69c6f371c",
  measurementId: "G-8154K80V21"
};
const firebaseApp = initializeApp(firebaseConfig);
global.db = getFirestore(firebaseApp);

window.ref = collection(global.db,"gigaphotos");
window.bref =collection(global.db,"bookmarks");
window.bhref =collection(global.db,"browsinghistory");
global.imref =collection(global.db,"internalmessages");
window.userHist =collection(global.db,"usersHistory");
window.popupsref =collection(global.db,"popups");
window.annotationsref =collection(global.db,"annotations");

window.aliases =collection(global.db,"aliases");
window.events =collection(global.db,"event");

window.messagingref =collection(global.db,"messaging");

window.mail =collection(global.db,"mail");

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>    
    <App />    
  </FirebaseAppProvider>,
  document.getElementById('root')
);


function AppAuthOk() {
  
  return(
    <div>
      
       <BrowserRouter>
       <Routes>
              
           
            <Route path='/:splatname' element={<GS />} />   
            <Route path='/photos' element={<Photos />} /> 
          
            <Route path='/gs/:splatname' element={<GS />} /> 

              </Routes>
        </BrowserRouter>
    </div>
  );
}
function AppAuthNotOk() {
  return(
    <div>
      not ok
      {/* <AuthenticationPage /> */}
    </div>
  );
}

function AppAll(){
  const { status, data: signInCheckResult } = useSigninCheck();

  console.log("appall");

  if (status === 'loading') {
    return <span>loading...</span>;
  }
return <AppAuthOk />;
  // if (signInCheckResult.signedIn === true || true) {
  if (signInCheckResult.signedIn === true ) {
    
  } else {
    return <AppAuthNotOk />;
  }
}

function App() {

  const app = useFirebaseApp();
  const auth = getAuth(app);

  const firestoreInstance = getFirestore(app);

 
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AppAll />       
      </FirestoreProvider>
    </AuthProvider>
  );
}