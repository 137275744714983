import React, { Component } from 'react';
import * as GaussianSplats3D from '@mkkellogg/gaussian-splats-3d';
import { useParams } from 'react-router-dom';

import {
    Vector3,
	Scene,
	WebGLRenderer,
	PerspectiveCamera,
	Mesh,
	MeshBasicMaterial,
  BoxGeometry
} from 'three';

let name = "";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}



class GaussianSplatComp3 extends Component {
    constructor(props) {
      super(props);

      let { splatname } = this.props.params;

   //   if (this.props.match != undefined) this.splatname = this.props.match.params.splatname;
      
      // const { splatname } = useParams<Params>;
 
       console.log(splatname);
      
       this.splatpath = "https://data2.gigascope.net/splats/rome.ksplat";

       if (splatname.includes("rome")){
        this.initialCameraPosition = [0.76, 0.93, -0.74];
       this.initialCameraLookAt = [ -0.37, 1.066, 0.22];
       }
       if (splatname.includes("cheval2")){
        this.initialCameraPosition = [	5.99680, 0.23452, -2.40757];
       this.initialCameraLookAt = [ -0.37, 1.066, 0.22];
       }

       if (splatname.includes("danceuse_soleil_v1")){
        this.initialCameraPosition = [	0.18459, 0.36189, -1.90241];
       this.initialCameraLookAt = [ 0.12647, 0.42777, -0.16513];
       }

       if (splatname != "") this.splatpath = "https://data2.gigascope.net/splats/"+ splatname +".ksplat";
 
       if (splatname == "salon") this.splatpath = "https://data2.gigascope.net/splats/ritz_salon.ksplat";
       if (splatname == "rome") this.splatpath = "https://data2.gigascope.net/splats/rome.ksplat";
       if (splatname == "garden") this.splatpath = "https://data2.gigascope.net/splats/garden.ksplat";
       if (splatname == "bonsai") this.splatpath = "https://data2.gigascope.net/splats/bonsai.ksplat";

       
  
      this.update = this.update.bind(this);
      
       this.state = {
        
      }; 
      
  }

  init(){


    //const {renderer, renderWidth, renderHeight} = this.setupRenderer();
    //this.renderer = renderer;
  //  const rootElement =document.getElementById("GSContainer");
  const renderWidth = window.innerWidth;
  const renderHeight = window.innerHeight;

    const rootElement = document.createElement('div');
    rootElement.style.width = renderWidth + 'px';
    rootElement.style.height = renderHeight + 'px';
    rootElement.style.position = 'relative';
  //  rootElement.style.left = '50%';
    rootElement.style.top = '50%';
   // rootElement.style.transform = 'translate(-50%, -50%)';

    document.getElementById("GSContainer").appendChild( rootElement );
   /* this.camera = this.setupCamera(renderWidth, renderHeight);
    this.threeScene = this.setupThreeScene();
    this.controls = this.setupControls(this.camera, this.renderer);*/

        this.viewer = new GaussianSplats3D.Viewer({
            sharedMemoryForWorkers: false,
            gpuAcceleratedSort: true,
            rootElement: rootElement,
            cameraUp: [0, -1, 0],
            streamView: true,
            initialCameraPosition: this.initialCameraPosition,
            initialCameraLookAt: this.initialCameraLookAt
        });

        this.viewer.addSplatScene(this.splatpath, {
      splatAlphaRemovalThreshold: 5,
      showLoadingUI: true,
      
      position: [0, 1, 0],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1]
  })
     .then(() => {
        this.viewer.start();
    });

   //   this.threeScene.add(this.viewer);

   // requestAnimationFrame(this.update);
  }
    
    update() {

       // if (this.controls!=undefined){
    /*    requestAnimationFrame(this.update);
        this.controls.update();
        this.renderer.render(this.threeScene, this.camera);
      //  }*/
        
    }

  setupRenderer() {
  /*  const renderWidth = 800;
    const renderHeight = 600;

    const rootElement = document.createElement('div');
  /*  rootElement.style.width = renderWidth + 'px';
    rootElement.style.height = renderHeight + 'px';
    rootElement.style.position = 'relative';
  //  rootElement.style.left = '50%';
    rootElement.style.top = '50%';*/
   // rootElement.style.transform = 'translate(-50%, -50%)';
/*
    document.getElementById("GSContainer").appendChild( rootElement );
 //   document.body.appendChild(rootElement);

    const renderer = new WebGLRenderer({
        antialias: false
    });
    renderer.setSize( window.innerWidth, window.innerHeight );
  //  renderer.setSize(renderWidth, renderHeight);
    rootElement.appendChild(renderer.domElement);

    return {
      'renderer': renderer,
      'renderWidth': renderWidth,
      'renderHeight': renderHeight,
     // 'rootElement':rootElement
    }*/
  }
  
  setupCamera(renderWidth, renderHeight) {
  /*  const camera = new PerspectiveCamera(65, renderWidth / renderHeight, 0.1, 500);
    camera.position.copy(new Vector3().fromArray([-1, -4, 6]));
    camera.lookAt(new Vector3().fromArray([0, 4, -0]));
    camera.up = new Vector3().fromArray([0, -1, -0.6]).normalize();
    return camera;*//*
  }

  setupThreeScene() {
    const threeScene = new Scene();
  /*  const boxColor = 0xBBBBBB;
    const boxGeometry = new BoxGeometry(2, 2, 2);
    const boxMesh = new Mesh(boxGeometry, new MeshBasicMaterial({'color': boxColor}));
    threeScene.add(boxMesh);
    boxMesh.position.set(3, 2, 2);*/
 /*   return threeScene;*/
  }

  setupControls(camera, renderer) {
    const controls = new GaussianSplats3D.OrbitControls(camera, renderer.domElement);
    controls.rotateSpeed = 0.5;
    controls.maxPolarAngle = Math.PI * .75;
    controls.minPolarAngle = 0.1;
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    return controls;
  }

  
  
  componentDidMount() {
    this.init();

    window.addEventListener( 'resize', this.onWindowResize, false );
    

  }



  componentDidUpdate(){

  }

  
  render() {


    
    return (
      

      <div id="3dcontainer" className="fullblack blackbackground">

      <div id="GSContainer" className="fullblack blackbackground">

	    </div>


      </div>
      
    );
  }
}

export default withParams(GaussianSplatComp3);
